import "./App.css";
import Navbar from "./Componants/Navbar.js";
// import About from "./Componants/About.js";
import Textform from "./Componants/TextForm.js";
import React, { useState } from "react";
import Alert from "./Componants/Alert";
// import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  const [mode, setMode] = useState("light");

  const [alert, setAlert] = useState(null);

  const showAlert = (message, type) => {
    setAlert({
      msg: message,
      type: type,
    });
  };

  const toggleMode = () => {
    if (mode === "light") {
      setMode("dark");
      document.body.style.backgroundColor = "#042743";
      showAlert("Dark mode has been enabled", "success");
    } else {
      setMode("light");
      document.body.style.backgroundColor = "white";
      showAlert("Light mode has been enabled", "success");
    }
  };

  return (
    <>
      {/* <Navbar title= "TextUtils" aboutText="About textutils"/> */}

      <div className="container">
        {/* <BrowserRouter> */}
        <Navbar
              title="React Projects"
              mode={mode}
              toggleMode={toggleMode}
            />
            <Alert alert={alert} />
          {/* <Routes> */}
            

            {/* <Route path="/About" element={<About />} /> */}
            {/* <Route */}
              {/* path="/TextForm"
              element={ */}
                <Textform heading="Enter a text To Analyse Below" mode={mode} />
              {/* } */}
            {/* /> */}
          {/* </Routes> */}
        {/* </BrowserRouter> */}
      </div>
    </>
  );
}

export default App;
