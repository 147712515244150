import React, { useState } from "react";

export default function Textform(props) {
  const handleUpClick = () => {
    console.log("Uppercase was clicked");
    let newtext = text.toUpperCase();
    setText(newtext);
    if (newtext === "") {
      alert("Please write something");
    }
  };

  const handleLoClick = () => {
    console.log("Lowercase was clicked");
    let newtext = text.toLowerCase();
    setText(newtext);

    if (newtext === "") {
      alert("Please write something");
    }
  };

  const handleOnChange = (event) => {
    console.log("On change");
    setText(event.target.value);
  };

  const clearCode = () => {
    console.log("Clear code was clicked");
    setText(""); // Set text state to an empty string
  };

  const copyCode = () => {
    console.log("Text Copy");
    var text = document.getElementById("myBox");
    text.select();
    navigator.clipboard.writeText(text.value);
  };

  const removeExtraSpace = () => {
    let newText = text.split(/[ ]+/);
    setText(newText.join(" "));
  };

  const [text, setText] = useState("");
  return (
    <>
      <div
        className="container"
        style={{ color: props.mode === 'dark' ? 'white' : 'black' }}>
          <h1 className="text-center mt-5 mb-5">
          <u>Text Converter</u>
        </h1>
        <h3 className="mb-4">{props.heading}</h3>
        <div className="mb-3">
          <textarea
            className="form-control border-3"
            value={text}
            id="myBox"
            rows="7"
            onChange={handleOnChange}
            style={{
              backgroundColor: props.mode === 'dark' ? 'grey' : 'white',
              color: props.mode === 'dark' ? 'white' : 'black'
            }}
          ></textarea>
        </div>
        <button className="btn btn-primary m-2" onClick={handleUpClick}>
          Convert to Uppercase
        </button>
        <button className="btn btn-primary m-2" onClick={handleLoClick}>
          Convert to Lowercase
        </button>
        <button className="btn btn-primary m-2" onClick={clearCode}>
          Clear Code
        </button>
        <button className="btn btn-primary m-2" onClick={copyCode}>
          Copy Code
        </button>
        <button className="btn btn-primary m-2" onClick={removeExtraSpace}>
          Remove Extra Spaces
        </button>
      </div>
      <div
        className="container my-3"
        style={{ color: props.mode==='dark'?'white':'black'}}
      >
        <h1>Your text Summary</h1>
        <p>
          <b>{text.split(" ").length}</b> Words and <b>{text.length}</b>{" "}
          Characters
        </p>
        <p>
          <b>{0.008 * text.split(" ").length}</b> Minutes to Read
        </p>
        <h2>Preview</h2>
        <p>{text.length>0?text:"Enter Something to Preview in it!"}</p>
      </div>
    </>
  );
}
